<template>
<b-link class="addBtn" to="/new-note">
<p class="h1 mt-1"><b-icon icon="plus"></b-icon></p>
</b-link>  

</template>

<script>
export default {
name:"AddBtn"
}
</script>

<style>

</style>