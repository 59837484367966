<template>
<!-- <div class="loading-s"> -->
<div class="loading">Loading&#8230;</div>
<!-- <img src="../assets/image/loading.png" width="300" /> -->

<!-- </div> -->
</template>

<script>
export default {

}
</script>

<style>

</style>