<template>
  <footer class="mt-5 mb-5">
      <hr />
      <div class="container">
          <div class="row">
              <div class="col text-center">2021 Copyright by zenklot</div>
          </div>
      </div>
  </footer>
</template>

<script>
export default {
name:"Footer"
}
</script>

<style>

</style>